import React, {useContext, useState, useEffect, useRef} from "react"
import UserContext from "./Context"
import LogNav from "./LogNav"
import callApi from "./CallApi"
import Message from "./Message"
import CampaignSuccess from "./CampaignSuccess"


let timeout;
export default function CampaignDash(props) {
    const [email,setEmail] = useState({
        email: "",
        subject: "",
    })


    const [openCustom, setOpenCustom] = useState(false)
    const [variablePlaces, setVariablePlaces] = useState([])

    const [newCampaign, setNewCampaign] = useState(false)
    const [asProfile, setAsProfile] = useState("true")
    const [statusMessage, setStatusMessage] = useState({message: "", type:""})
    const [campaign,setCampaign] = useState({
        target: "homesellers",
        credits: 0,
        aiThreshold: 50,
        address: "",
    })
    useEffect(() => {

        setTimeout(() => {
            if (statusMessage.message !== "") {
                timeout = setStatusMessage({message: "", type:""})
            }
        }, 5000)
        
        

    }, [statusMessage])

    

    const modifyCampaign = (e) => {

        setCampaign({...campaign, [e.target.id]: e.target.value})

    }
    
    const user = useContext(UserContext)


    const startCampaign = () => {
        clearTimeout(timeout)
        setStatusMessage({message: "", type:""})
        window.scrollTo(0, 0);

        if ((campaign.target === "homebuyers") && (campaign.address === "")) {
            setStatusMessage({message: "Enter a valid address when targeting homebuyers", type: "err"})
            return
        } else if (campaign.credits <= 99) {
            setStatusMessage({message: "Each campaign needs at least an 100 credit budget", type: "err"})
            return
        } else if (campaign.credits > user.credits) {
            setStatusMessage({message: "You don't have enough credits to start this campaign", type: "err"})
            return
        }

        const campaignData = {...campaign, message: {email}}

        callApi("/startCampaign","POST", campaignData).then((res) => {
            if (res.message === "insufficient credits") {
                setStatusMessage({message: "Insufficient credits to start this campaign", type: "err"})
            } else if (res.message === 'not logged in') {
                setStatusMessage({message: "Not logged in", type: "err"})
                window.location.replace("/login")
            } else if (res.code === "err") {
                setStatusMessage({message: "Something went wrong", type: "err"})
            } else if (res.code === "ok") {
                setStatusMessage({message: "All went well", type:"ok"})
                setCampaign({
                    target: "homesellers",
                    credits: 0,
                    aiThreshold: 50,
                    address: "",
                })
                setNewCampaign(true)
            } else {
                setStatusMessage({message: "Something went wrong", type: "err"})
            }

        })

    }
    // const setCaretPosition = (position) => {
    //     if (editableDivRef.current) {
    //       const range = document.createRange();
    //       const selection = window.getSelection();
    
    //       range.setStart(editableDivRef.current.firstChild, position);
    //       range.collapse(true);
    
    //       selection.removeAllRanges();
    //       selection.addRange(range);
    //     }
    //   };
    

    const divRef = useRef(null)
    // const setCaretPosition = (position) => {
    //     const selection = window.getSelection();
    //     console.log(selection)
    //     if (selection && divRef.current) {
    //       const range = document.createRange();
    //       console.log(divRef.current.childNodes)
    //       const textNode = divRef.current.childNodes[node] || divRef.current; // Use the first child node or the div itself

    //       range.setStart(textNode, position);
    //       range.collapse(true);
    //       selection.removeAllRanges();
    //       selection.addRange(range);
    //     }
    //   };
    
    //   const getCaretPosition = () => {
    //     const selection = window.getSelection();
    //     if (selection && selection.rangeCount > 0) {
    //       const range = selection.getRangeAt(0);
    //       return range.startOffset;
    //     }
    //     return 0;
    //   };


//     const beforeInput = (e) => {
//         console.log(e.data)
//         const caretPosition = getCaretPosition(); // Get the caret position
//         console.log(caretPosition);

// // Update the email state
//         setEmail((prevEmail) => {
//     // Text before and after the caret
//             const beforeCaret = prevEmail.email.substring(0, caretPosition);
//             const afterCaret = prevEmail.email.substring(caretPosition);

//             console.log(beforeCaret); // Debug: Text before the caret
//             console.log(afterCaret);  

//     // Return updated email with the new character inserted at the caret position
//             return {
//                 ...prevEmail,
//                 email: beforeCaret + String(e.data) + afterCaret,
//             };
// });
//         try {
//             requestAnimationFrame(() => setCaretPosition(caretPosition+1));
            
//         } catch(e) {
//             requestAnimationFrame(() => setCaretPosition(0));
//         }
        
//         e.preventDefault();
        


        
//     }

    
    // const handleInput = (e) => {
    //     e.preventDefault();
    //     const caretPosition = getCaretPosition()
        
    //     console.log("got to handle input")
    //     setEmail((prevEmail) => {
    //         if (prevEmail.email.length > 0) {
                
    //             return {...prevEmail, email: divRef.current.textContent}
    //         }
    //     }) 
        
    //     console.log("this occured")
       
    // }
    
    const [editableContent, setEditableContent] = useState(''); // Store content in state
    // const setCaretPosition = (position) => {
    //     if (divRef.current) {
    //       const range = document.createRange();
    //       const sel = window.getSelection();
    
    //       let charCount = 0;
    //       let foundNode = null;
    
    //       for (const child of divRef.current.childNodes) {
    //         if (child.nodeType === Node.TEXT_NODE) {
    //           const nodeLength = child.textContent.length;
    //           if (charCount + nodeLength >= position) {
    //             foundNode = child;
    //             break;
    //           }
    //           charCount += nodeLength;
    //         } else if (child.nodeType === Node.ELEMENT_NODE && child.tagName === 'DIV') {
    //             // Handle div elements as single characters for caret positioning
    //             if (charCount + 1 >= position) { // Treat each div as one character
    //                 foundNode = child;
    //                 break;
    //             }
    //             charCount++; // Increment for the div
    //         }
    
    //       }
    
    //       if (foundNode) {
    //         if (foundNode.nodeType === Node.TEXT_NODE) {
    //             range.setStart(foundNode, position - charCount);
    //         } else if (foundNode.nodeType === Node.ELEMENT_NODE && foundNode.tagName === 'DIV') {
    //             range.setStartBefore(foundNode);
    //         }
    //         range.collapse(true);
    //         sel.removeAllRanges();
    //         sel.addRange(range);
    //       }
    //     }
    //   };
    
    //   const getCaretPosition = () => {
    //     const sel = window.getSelection();
    //     if (sel.rangeCount) {
    //       const range = sel.getRangeAt(0);
    //       let preCaretTextRange = range.cloneRange();
    //       preCaretTextRange.selectNodeContents(divRef.current);
    //       preCaretTextRange.setEnd(range.startContainer, range.startOffset);
    //       return preCaretTextRange.toString().length;
    //     }
    //     return 0;
    //   };
    
    // useEffect(() =>s {
    //     // const caretPosition = getCaretPosition();

    //     //     requestAnimationFrame(() => setCaretPosition(caretPosition));
    //   }, [editableContent]);

    // onBeforeInput={(e) => handleBeforeInput(e)}

    

    // const handleAddDiv = () => {
    //     setEditableContent((prevContent) => {
    //       const newContent = prevContent + "<div contentEditable='false' class='badge inline-block disabled badge-secondary select-none'>AI Name</div><p></p>";
    //       setNode((prevNode) => prevNode+1);
    //       return newContent;
    //     });
    //   };
    // const handleChange = (e) => {
    useEffect(() => {
        console.log("this was called")

        if (openCustom && (email.email !== "")) {
            divRef.current.value = email.email;
        }
        
        




    }, [email])
      
      
    //   setEditableContent(e.target.innerHTML); // Update state on content change
    //   const caretPosition = getCaretPosition();
    // //   console.log(caretPosition)
    //     requestAnimationFrame(() => setCaretPosition(caretPosition));
    // };


    
    return (

        <>
        {(newCampaign) ? 
        <CampaignSuccess setNewCampaign={setNewCampaign} /> : 


        <div className="sm:p-10 p-4 relative">
            {(statusMessage.message !== "") && (
                    <Message error={statusMessage.message} type={statusMessage.type} />
            )}
            <p className="font-1 font-semibold text-bold sm:text-2xl text-xl sm:pl-5 pl-2">Start a New Lead Campaign</p>
            
            <div className="w-full sm:mt-5 mt-2 bg-base-100 p-2 sm:px-5">
                <div className="bg-base-300 p-4 ">
                

                <div className="w-fit flex flex-col gap-2 mt-2">
                    <p className="font-1 font-bold">Intended target:</p>
                        <select id="target" value={campaign.target} onChange={(e) => modifyCampaign(e)} className="select select-primary font-3 font-semibold">
                        <option className="homebuyers">Homebuyers</option>
                        <option className="homesellers">Homesellers</option>

                    </select>

                    

                </div>
                {(campaign.target.toLowerCase() === "homebuyers") && (
                        <div className="mt-2 p-3 bg-base-100 w-fit">
                            <p className="font-3 font-semibold">Please enter the address of the area you are trying to target</p>
                            <p className="font-3 text-xs">Sniphomes will automatically outreach to people within a certain radius from the inputted address</p>
                            <input id="address" type="text" value={campaign.address} onChange={(e) => modifyCampaign(e)} className="input input-secondary font-1 input-sm" placeholder="Address" />
                        </div>
                    )}
                <div className="flex flex-row gap-2 mt-4 items-center">
                    <p className="font-1 font-bold">Do you plan to target your operating area (as mentioned on your profile)</p>
                    <select className="select select-sm font-3" value={asProfile} onChange={(e) => {setAsProfile(e.target.value)}}>
                        <option value={"true"}>Yes</option>
                        <option value={"false"}>No</option>
                    </select>
                </div>
                {(asProfile === "false") && (
                    <div className="text-error font-bold font-3">
                        <p>Please update your settings to your target operating area</p>
                    </div>
                )}
                {(asProfile === "true") && (
                    <p className="font-1 font-semibold text-sm">(Listed areas): {user?.operatingArea.map((area, i) => 
                {if (i !== user.operatingArea.length-1) {
                    return area + ", "
                } else {
                    return area
                }
                }
                
                
                )}</p>
                )}
                



                <div className="mt-4 bg-base-200 w-full p-2">
                    <div className="w-full h-full flex sm:flex-row flex-col items-center  gap-4 justify-items-center">
                    <p className="font-1 font-semibold">Campaign Budget:</p>
                    <div className="flex flex-row items-center bg-neutral rounded-r-full pr-2 gap-2">
                        <input id="credits" type="number" className="input  font-3" placeholder="200 Credits" value={campaign.credits} onChange={(e) => {modifyCampaign(e)}} />
                        <p className="font-1 font-bold">Credits</p>

                    </div>
                    </div>

                    <div className="p-3 bg-base-100 mt-2 font-1 font-semibold">
                        <p>This credit spending would generate roughly <span className="sm:p-2 p-1 bg-error text-bold text-lg text-neutral">{Math.floor(campaign.credits*0.001)} leads</span></p>
                        <p className="font-3 text-xs mt-2">*Note: This isn't a guarantee. The actual amount of leads generated could be higher<br/> or lower from this estimate number. This estimate doesn't account <br/> for the area you are located in.*</p>
                    
                    </div>
                    
                    
                    
                
                </div>

                <p className="font-1 font-bold text-lg mt-8">Customize your agent:</p>

                <div className="flex flex-row gap-2 items-center font-3 font-bold mt-2">
                    <p>Ai Agent Name:</p>
                    <input type="text" className="font-1 input" disabled value={user.aiSettings.name} />
                   

                </div>
                <p className="text-sm text-error font-3 font-semibold">You can change this value in your settings</p>
                
                <div className="mt-2 w-full p-3 bg-base-100">
                    <p className="font-3 font-semibold">Ai Threshold value</p>
                    
                    <input id="aiThreshold" value={campaign.aiThreshold} onChange={(e) => modifyCampaign(e)} type="range" min={0} max="100" className="range w-full range-sm sm:w-2/6 mt-2 range-warning" />
                    <p className="font-3 font-semibold">This value affects the threshold at which the ai will recommend leads to you.</p>
                    <ul className="list-disc ml-5 font font-2">
                        <li>
                        <p>The higher the value, the higher the threshold (resulting in the <strong>most </strong>qualified leads)</p>
                        </li>
                        <li>
                        <p>The lower the value, the lower the threshold (resulting in <strong>less </strong>qualified leads)</p>
                        </li>
                    </ul>
                    <p className="mt-2 font-1 font-semibold">Experiment with this value throughout your campaigns</p>
                   
                    

                </div>


                <div className="mt-4">
                    <p className="font-3 font-bold">Ai Referral Info:</p>
                    

                    <div>
                        <p className="font-1 font-semibold">When Sniphomes finds a willing lead, the lead will be provided some contact information expecting a call in the future.</p>
                        <div className="mt-2 flex flex-row items-center gap-2">
                            <p className="font-3 font-semibold">Non-Personal Phone Number</p>
                            <input type="text" className="input font-1 font-semibold" placeholder="Ex: 123-4123-1233" disabled value={user.phoneNumber} />
                        </div>
                    </div>

                </div>

                </div>

              
                
                
                
            </div>
            <div data-theme="coffee" className="sm:mt-3 bg-base-200 mx-4 mt-2 p-2 sm:px-5">
                
                <div className=" p-4 ">
                    <p className="font-3 font-bold text-xl">Customize your outreach email:</p>
                    <div className="flex flex-row gap-2 items-center p-3 bg-neutral border mt-2">
                        <input type="checkbox" className="checkbox checkbox-accent" onClick={() => setOpenCustom((prevCustom) => !prevCustom)} />

                        <div>
                            <p className="font-1 font-bold">Customize your own email</p>
                        </div>
                    </div>
                   
                    {/* <div className="btn mt-4 font-3 btn-primary btn-outline">Let AI handle it<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path fillRule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clipRule="evenodd" />
</svg>
</div> */}

                    
                   {openCustom ? 
                        <><div className="mt-4">

                        <div className="mockup-window bg-base-100 border">
                    
                            <div className="p-2">
                            <input value={email.subject} onChange={(e) => {
                                
                                setEmail((prevEmail) => {
                                    return {...prevEmail, subject: e.target.value}
                                }
                            )
                        }} placeholder="Subject line:" className="input input-ghost w-full font-3" />
                            <hr className="w-[95%]" />
                        <div className=" w-full">
    
                            {/* <textarea className="w-full textarea textarea-ghost my-2 min-h-96 font-3" placeholder="Write here" /> */}
                            {/* <div contentEditable onInput={handleChange} suppressContentEditableWarning={true} ref={divRef} dangerouslySetInnerHTML={{ __html: editableContent }}
                              className="textarea textarea-ghost w-full min-h-96 font-3 rounded-none gap-3 flex flex-row" placeholder="Type your email here...">
                                
                                
                           
                             
              
                                
                             
    
                             
                            </div> */}
    
                            <textarea ref={divRef} onChange={(e) => {setEmail((prevEmail) => {return {...prevEmail, email: e.target.value}})}} className="w-full textarea textarea-ghost my-2 min-h-96 font-3" placeholder="Email Body...    " />
                            
                            
                            <hr className="w-[95%] my-1" />
                            <p className="font-1">Variables:</p>
                            {/* <p className="text-x">Click on one to add</p> */}
    
    
                            <div className="flex flex-row flex-wrap gap-2">
    
                            <div className="btn btn-sm btn-outline btn-primary !rounded-lg" onClick={(e) => {
                                setEmail((prevEmail) => {
                                    return {...prevEmail, email: prevEmail.email + " ${AiName} "}
                                })
    
                            }}>
                                <p className="font-3 font-bold">AI Name</p>
                            </div>
                            <div className="btn btn-sm btn-outline btn-secondary !rounded-lg" onClick={(e) => {
                                setEmail((prevEmail) => {
                                    return {...prevEmail, email: prevEmail.email + " ${leadName} "}
                                })
    
                            }}>
                                <p className="font-3 font-bold">Lead Name</p>
                            </div>
    
                            <div className="btn btn-sm btn-outline btn-accent !rounded-lg" onClick={(e) => {
                                setEmail((prevEmail) => {
                                    return {...prevEmail, email: prevEmail.email + " ${leadAddress} "}
                                })
    
                            }}>
                                <p className="font-3 font-bold">Lead Address</p>
                            </div>
    
                            <div className="btn btn-sm btn-outline btn-neutral !rounded-lg" onClick={(e) => {
                                setEmail((prevEmail) => {
                                    return {...prevEmail, email: prevEmail.email + " ${leadCity} "}
                                })
    
                            }}>
                                <p className="font-3 font-bold">Lead City</p>
                            </div>
                           
    
    
                            </div>
                            <div className="p-4 bg-base-300 mt-2 rounded border border-secondary">
                                <div className="flex flex-row items-center gap-2">
                                    <p className="font-3 font-bold text-lg">Pro Tip</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4">
      <path d="M12 .75a8.25 8.25 0 0 0-4.135 15.39c.686.398 1.115 1.008 1.134 1.623a.75.75 0 0 0 .577.706c.352.083.71.148 1.074.195.323.041.6-.218.6-.544v-4.661a6.714 6.714 0 0 1-.937-.171.75.75 0 1 1 .374-1.453 5.261 5.261 0 0 0 2.626 0 .75.75 0 1 1 .374 1.452 6.712 6.712 0 0 1-.937.172v4.66c0 .327.277.586.6.545.364-.047.722-.112 1.074-.195a.75.75 0 0 0 .577-.706c.02-.615.448-1.225 1.134-1.623A8.25 8.25 0 0 0 12 .75Z" />
      <path fillRule="evenodd" d="M9.013 19.9a.75.75 0 0 1 .877-.597 11.319 11.319 0 0 0 4.22 0 .75.75 0 1 1 .28 1.473 12.819 12.819 0 0 1-4.78 0 .75.75 0 0 1-.597-.876ZM9.754 22.344a.75.75 0 0 1 .824-.668 13.682 13.682 0 0 0 2.844 0 .75.75 0 1 1 .156 1.492 15.156 15.156 0 0 1-3.156 0 .75.75 0 0 1-.668-.824Z" clipRule="evenodd" />
    </svg>
    
                                </div>
    
                                <div className="font-3 text-sm">
                                    Personalize your email by clicking on any variable to add it to your email.
                                    
                                </div>
    
                            </div>
                            
                        </div>
    
                            
    
                            </div>
                        
    
                        </div>
                        
                        </div></> : <></>
                    }

                    
                    
                </div>
            </div>
            
            
            
            <button className="btn btn-accent btn-lg font-3 text-xl ml-4  mt-2" onClick={startCampaign}>
                    Start Campaign

            </button>
                


        </div>
        
        
        
        }

        






        </>
    )
}

