import React from "react";
import Navbar from "./Navbar"
import Footer from "./Footer"


export default function Compliance(props) {




    return(

        <>
        
        <Navbar />

        <div className="sm:w-4/6 mx-auto p-4 sm:mt-14 mt-5 rounded text-neutral-content">

            <div className="flex flex-row gap-2">
                <div className="badge badge-outline badge-primary">
                    <p className="font-1">TCPA Guidelines</p>
                    

                </div>
                <div className="badge badge-outline badge-primary">
                    <p className="font-1">CAN-SPAM Act</p>
                    

                </div>

            </div>

            <h1 className="font-1 text-3xl font-bold">How Sniphomes stays compliant amidst new guidelines</h1>

            <p className="font-3">Written by: Sebastian Hernandez-Tavares</p>


            <div className="mt-10">


                <h2 className="font-3 font-bold mb-3">How Sniphomes Ensures Compliance with TCPA and CAN-SPAM Regulations</h2>
                <p className="font-3 text-lg">At Sniphomes, we take compliance seriously. As a lead generation platform built to help real estate agents connect with potential clients, we ensure that all our outreach efforts follow the strict guidelines set by the Telephone Consumer Protection Act (TCPA) and the CAN-SPAM Act. Our goal is to provide high-quality, AI-powered lead generation while respecting consumer privacy and communication preferences.</p>

            </div>


            <div className="mt-10">
            <h2 className="font-3 font-bold mb-3">Staying TCPA Compliant</h2>
                <p className="font-3">The TCPA regulates phone calls, text messages, and auto-dialing practices to prevent unwanted solicitations. At Sniphomes, we prioritize transparency and consumer consent in every interaction.</p>


                <ul className="list-decimal ml-12 font-3 mt-4">
                    <li className="text-2xl font-bold">Obtaining Clear Consent</li>
                        <p className="my-5">We only contact homeowners and potential leads who have provided explicit consent or whose contact information is publicly available within legally permissible databases. We never cold-call random numbers or use deceptive tactics to reach potential clients.</p>
                    <li className="text-2xl font-bold">Respecting the Do Not Call List  </li>

                    <p className="my-5">Sniphomes cross-references all numbers against the National Do Not Call Registry and internal do-not-call lists to ensure that we only reach out to people open to receiving communications from real estate professionals.</p>

                    <li className="text-2xl font-bold">No Unwanted Robocalls</li>


                    <p className="my-5">Unlike many lead generation services that rely on prerecorded messages and auto-dialers, Sniphomes ensures that all outreach is personalized, compliant, and free from robocalls that violate TCPA rules.</p>
                
                    <li className="text-2xl font-bold">Easy Opt-Out Options</li>

                    <p className="my-5">If a homeowner no longer wishes to be contacted, they can easily opt out with a single response. We honor all opt-out requests immediately, ensuring that recipients have control over the messages they receive.</p>
                </ul>


             


            </div>


            <div>
                <h2 className="font-3 font-bold mb-3">Following CAN-SPAM Guidelines for Email Outreach</h2>
                <p className="font-3">Email is a powerful tool in real estate marketing, but spammy, non-compliant emails can do more harm than good. That’s why Sniphomes strictly follows the CAN-SPAM Act to ensure every email is professional, compliant, and well-received.</p>        
            </div>

            <div className="mt-10">
          

                <ul className="list-decimal ml-12 font-3 mt-4">
                    <li className="text-2xl font-bold">No Misleading Subject Lines</li>
                        <p className="my-5">Our emails are honest and transparent—no clickbait, deceptive offers, or misleading claims. Real estate agents can trust that every email aligns with ethical marketing standards.</p>
                    <li className="text-2xl font-bold">Clear Identification & Contact Information </li>

                    <p className="my-5">Each email sent through Sniphomes clearly identifies the sender, provides a physical mailing address, and makes it easy for recipients to know who is contacting them and why.</p>

                    <li className="text-2xl font-bold">Instant Opt-Outs</li>


                    <p className="my-5">We make opting out simple. If a recipient no longer wants to receive emails, they can unsubscribe with one click, and we ensure they never receive another message.</p>
                
                    <li className="text-2xl font-bold">Personalized, Relevant Content</li>

                    <p className="my-5">Rather than spamming inboxes with generic sales pitches, Sniphomes sends personalized emails tailored to each lead’s location and potential interest in selling or buying property. This ensures our outreach remains relevant and valuable.</p>
                </ul>


             


            </div>


            <div>
                <h2 className="font-3 font-bold mb-3">Why does this all matter?</h2>
                <p className="font-3">Beyond just following the law, Sniphomes believes in ethical, effective communication. By ensuring all calls and emails comply with TCPA and CAN-SPAM regulations, we help real estate agents build trust with potential clients, avoid hefty fines, and maintain a positive reputation.

With Sniphomes, you get AI-powered lead generation that is not just effective—but also compliant, ethical, and built for long-term success.

Ready to generate high-quality leads while staying compliant? Get started with <a href="/signup" className="link link-primary">sniphomes</a> today!







</p>
            </div>


        </div>
        <div className="mt-4">
        <Footer />
        </div>
       




        </>
    )
}