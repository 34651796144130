import React, {useState, useContext} from "react"
import UserContext from "./Context"
import { FaNewspaper, FaCog } from "react-icons/fa";




export default function LogNav(props) {

    const user = useContext(UserContext)

    const [name,setName] = useState("Sebastian Hernandez-Tavares")
    






    return (

        <>
        <div className="sm:w-[15vw] w-full border-r-2 fixed h-[90vh] bg-base-100 flex flex-col gap-2 font-1 top-[10vh] left-0" data-theme="coffee">
            <div className="relative w-full h-full mt-4">

            <div className=" bg-base-100">

           
            <div className="w-fit mx-auto">
            <btn className="btn mt-2 btn-base-100 mx-auto w-fit btn-outline" onClick={() =>props.setMainNav("Dashboard")}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path fillRule="evenodd" d="M2.25 2.25a.75.75 0 0 0 0 1.5H3v10.5a3 3 0 0 0 3 3h1.21l-1.172 3.513a.75.75 0 0 0 1.424.474l.329-.987h8.418l.33.987a.75.75 0 0 0 1.422-.474l-1.17-3.513H18a3 3 0 0 0 3-3V3.75h.75a.75.75 0 0 0 0-1.5H2.25Zm6.54 15h6.42l.5 1.5H8.29l.5-1.5Zm8.085-8.995a.75.75 0 1 0-.75-1.299 12.81 12.81 0 0 0-3.558 3.05L11.03 8.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l2.47-2.47 1.617 1.618a.75.75 0 0 0 1.146-.102 11.312 11.312 0 0 1 3.612-3.321Z" clipRule="evenodd" />
</svg>

                <p className="text-center font-1">Account Dashboard</p>
            </btn>
            </div>
            
            
            
            <div className="p-4 w-full flex flex-col items-center justify-items-center transition-all">
                    
            <div className=" w-full !rounded-sm hover:bg-base-300 cursor-pointer select-none p-2" onClick={() => {props.setMainNav("Dashboard")}}>
                <div className="flex flex-row items-center justify-start ">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4">
  <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
</svg>
                    <div className="flex flex-row items-center gap-1 w-full justify-around">
                    <div className="flex flex-row items-center gap-1">
                    <FaNewspaper />
                    <p className="font-1">Dashboard</p>
                    </div>
                    
                    </div>
                   
                </div>
                




                </div>
                <div className=" w-full !rounded-sm hover:bg-base-300 cursor-pointer select-none p-2" onClick={() => {props.setMainNav("Settings")}}>
                <div className="flex flex-row items-center justify-start ">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4">
  <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
</svg>
                    <div className="flex flex-row items-center gap-1 w-full justify-around">
                    <div className="flex flex-row items-center gap-1">
                    <FaCog />
                    <p className="font-1">Settings</p>
                    </div>
                    
                    </div>
                   
                </div>
                




                </div>
                <div className=" w-full !rounded-sm hover:bg-base-300 cursor-pointer select-none p-2" onClick={() => {props.setMainNav("Pricing")}}>
                <div className="flex flex-row items-center justify-start ">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4">
  <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
</svg>
                    <div className="flex flex-row items-center gap-1 w-full justify-around">
                    <div className="flex flex-row items-center gap-1">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path d="M4.5 3.75a3 3 0 0 0-3 3v.75h21v-.75a3 3 0 0 0-3-3h-15Z" />
  <path fillRule="evenodd" d="M22.5 9.75h-21v7.5a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-7.5Zm-18 3.75a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z" clipRule="evenodd" />
</svg>

                    <p className="font-1">Pricing</p>
                    </div>
                    
                    </div>
                   
                </div>
                




                </div>
                <div className=" w-full !rounded-sm hover:bg-base-300 cursor-pointer select-none p-2" onClick={() => {props.setMainNav("Leads")}}>
                <div className="flex flex-row items-center justify-start ">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4">
  <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
</svg>
                    <div className="flex flex-row items-center gap-1 w-full justify-around">
                    <div className="flex flex-row items-center gap-1">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path fillRule="evenodd" d="M3.792 2.938A49.069 49.069 0 0 1 12 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 0 1 1.541 1.836v1.044a3 3 0 0 1-.879 2.121l-6.182 6.182a1.5 1.5 0 0 0-.439 1.061v2.927a3 3 0 0 1-1.658 2.684l-1.757.878A.75.75 0 0 1 9.75 21v-5.818a1.5 1.5 0 0 0-.44-1.06L3.13 7.938a3 3 0 0 1-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836Z" clipRule="evenodd" />
</svg>

                    <p className="font-1">Leads</p>
                    </div>
                    
                    </div>
                   
                </div>
                




                </div>
                <div className=" w-full !rounded-sm hover:bg-base-300 cursor-pointer select-none p-2 sm:hidden block" onClick={() => {props.setMainNav("Campaign")}}>
                <div className="flex flex-row items-center justify-start ">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4">
  <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
</svg>
                    <div className="flex flex-row items-center gap-1 w-full justify-around">
                    <div className="flex flex-row items-center gap-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
</svg>

                    <p className="font-1">Campaign</p>
                    </div>
                    
                    </div>
                   
                </div>
                




                </div>
                
            </div>
            </div>

            <div className="absolute bottom-0 left-0 h-[10vh] py-4 flex flex-col justify-center px-4 w-full bg-base-200">
                <div className="">
                    <p className="font-1 text-lg"> Credits: <span className="font-bold p-2 bg-base-100">{user.credits}</span></p>
                    

                </div>
                {/* <div className="leading-none">
                <p className="font-1 font-bold text-lg">Sniphomes</p>
                <p className="font-3 font-sm">Copyright {new Date().getFullYear()}</p>

                </div> */}
                
                
        
            <p></p>
           

            </div>


            </div>
            
        
            

        </div>
           




        </>
    )
}